import firebase from 'firebase/app'
import firebaseConfig from './config';
import 'firebase/firestore';
import 'firebase/auth';

export default firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

// for registering students by users
export const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");
