import {createSelector} from 'reselect'

const userSelector = state => state.auth

export const selectUser = createSelector(
  [userSelector],
  auth => auth.user
);

export const selectUserLoading = createSelector(
  [userSelector],
  auth => auth.loading
);