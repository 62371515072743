import React from 'react';
import caret from "../../assets/images/caret.svg";
import styled from "styled-components";
import theme from "../../styles/theme";
import {NavLink} from "react-router-dom";

const ProfileDropdown = ({label, children}) => {
  return (
    <Wrap>
      <DropLink to="/" exact>
        {label}&nbsp;<img width={10} src={caret} alt=""/>
      </DropLink>
      <div>
        {children}
      </div>
    </Wrap>
  );
};

export default ProfileDropdown;

const Wrap = styled.div`
  position: relative;

  & > div {
    display: none;
    position: absolute;
    right: 0;
    top: 1.3rem;
    z-index: 10;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: ${theme.shadow};
    width: 360px;
    overflow: hidden;
    padding: 0 1rem;
  }
  
  &:hover > div {
    display: block;
  }
`

const DropLink = styled(NavLink)`
  font-family: ${theme.fontTitle};
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 110%;
  
  &.active {
    color: black;
    pointer-events: none;
  }
`