import React, { useState } from 'react';
import { Button, H1, H3 } from '../../styles/shared';
import { Link } from 'react-router-dom';
import { HeroWrap, Logos, SignupButtonWrap, Announce } from './Home.styled';
import Modal from '../../components/Modal';
import darkeinu from '../../assets/images/logo-darkeinu.svg'
import fjc from '../../assets/images/logo-fjc.png'
import fjcWepb from '../../assets/images/logo-fjc.webp'
import avner from '../../assets/images/logo-or-avner.png'
import avnerWebp from '../../assets/images/logo-or-avner.webp'
import ImgWebp from '../../components/ImgWebp';

export const Hero = () => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <HeroWrap>
        <H1 crop>Олимпиада Даркейну 5782</H1>
        <p>по еврейским знаниям для детей и&nbsp;подростков</p>
        <Announce>
          <span className="accent">
            Первый тур Олимпиады 5782 пройдет с&nbsp;30&nbsp;января по&nbsp;6&nbsp;февраля 2022&nbsp;года.
            Чтобы успешно подготовиться к&nbsp;нему&nbsp;– регистрируйся на&nbsp;
            <a target="_blank" rel="noopener noreferrer"
               href="https://docs.google.com/forms/d/e/1FAIpQLScapQf0O3dOgGqQtVWwHagyjGqeNyVii3JLVuwOOWI6FX6AmA/viewform">
              онлайн-курс подготовки&nbsp;›
            </a>
          </span>
        </Announce>
        <SignupButtonWrap>
          <Button as={Link} to="/signup" outline="true">Регистрация</Button>
          <div className="hint" onClick={() => setModal(true)}>Как регистрироваться?</div>
        </SignupButtonWrap>
        <Logos>
          <a href="https://www.darkeinu.org/olimpiada/" target="_blank" rel="noopener noreferrer">
            <img height={50} width={70} src={darkeinu} alt="Даркейну" />
          </a>
          <a href="https://fjc-fsu.org/" target="_blank" rel="noopener noreferrer">
            <ImgWebp height={70} width={70} src={fjc} srcWebp={fjcWepb} alt="Федерация еврейских общин" />
          </a>
          <ImgWebp height={70} width={70} src={avner} srcWebp={avnerWebp} alt="Ор Авнер" />
        </Logos>
      </HeroWrap>

      <Modal modal={modal} setModal={setModal} width={400}>
        <H3>Видеоинструкции по регистрации</H3>
        <div><a href="https://youtu.be/U9n6e7HtVZo" target="_blank" rel="noopener noreferrer">לרבנית</a></div>
        <div><a href="https://youtu.be/38I8Pt-xvD0" target="_blank" rel="noopener noreferrer">Учителю</a></div>
        <div><a href="https://youtu.be/guPby6L8Dog" target="_blank" rel="noopener noreferrer">Родителю</a></div>
        <div><a href="https://youtu.be/XU3XXvff9HE" target="_blank" rel="noopener noreferrer">Участнику</a></div>
      </Modal>
    </>
  );
};
