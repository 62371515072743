import React, {useState} from 'react';
import {LoginWrap, TabPanel} from "./Home.styled";
import {LoginKidsForm} from "./LoginKidsForm";
import {LoginAdultForm} from "./LoginAdultForm";
import {TabsSwitch} from "./TabsSwitch";
import {auth} from '../../firebase';
import validateLogin from "../../utils/validateLogin";
import {showError} from "../../components/Toasts";
import {H2} from "../../styles/shared";

const defaultState = {login: '', code: '', email: '', password: '', tab: 0};

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(defaultState);
  const {tab} = values;

  const onChange = (value, name) => {
    if (name === 'login') {
      setValues(prev => ({
        ...prev,
        [name]: `${value.trim()}@mailfake.com`,
      }))
    } else {
      setValues(prev => ({
        ...prev,
        [name]: value.trim(),
      }))
    }
  }

  const onSubmit = async e => {
    e.preventDefault();

    const errors = validateLogin(values, tab);
    if (errors.length > 0) {
      return showError(errors);
    }

    try {
      setLoading(true);
      if (tab === 0) {
        await auth.signInWithEmailAndPassword(values.login, values.code);
      } else {
        await auth.signInWithEmailAndPassword(values.email, values.password);
      }
      await setLoading(false);
    } catch (e) {
      setLoading(false);
      const wrongCredsText = tab === 0 ? `Неправильный логин или код` : `Неправильная почта или пароль`;
      if (e.code === 'auth/wrong-password' || e.code === "auth/user-not-found" || e.code === "auth/invalid-email") {
        return showError(wrongCredsText);
      } else {
        return showError(`Ошибка. ${e.message}`);
      }
    }
  }

  const formProps = {onChange, values, onSubmit, loading};

  return (
    <LoginWrap tab={tab}>
      <TabsSwitch tab={tab} setValues={setValues}/>
      <TabPanel>
        <H2>Вход</H2>
        {
          tab === 0
            ? <LoginKidsForm {...formProps}/>
            : <LoginAdultForm {...formProps}/>
        }
      </TabPanel>
    </LoginWrap>
  );
};