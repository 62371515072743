import styled from "styled-components";
import theme from "../../styles/theme";
import bp from "../../styles/bp";

export const HomeWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

export const HeroWrap = styled.div`
  max-width: 550px;
  width: 100%;
  text-align: center;
  
  ${bp.sm`
    padding-top: 3rem; 
    text-align: left;
  `}
  
  p {
    margin-bottom: .6rem;
  }
`

export const Announce  = styled.div`
  margin-bottom: 1rem;

  .accent {
    background-color: #ffeba5;
  }
`

export const SignupButtonWrap = styled.div`
  display: block;
  ${bp.sm`display: flex`};
  align-items: center;
  
  .hint {
    font-size: .75rem;
    color: ${theme.primary};
    margin: 0 1rem 1rem;
    cursor: pointer;
  }
`


export const LoginWrap = styled.div`
  max-width: 400px;
  width: 100%;
  text-align: center;
`

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
`

export const Tab = styled.button`
  font-size: 1.1rem;
  font-weight: ${props => props.active ? "600" : "400"};
  font-family: ${theme.fontTitle};
  background-color: ${props => props.active ? "#fff" : "transparent"};
  border-width: 1px 1px 0 1px;
  border-color: rgba(0,0,0,.05);
  border-style: solid;
  border-radius: 6px 6px 0 0;
  padding: .6rem 1rem .3rem;
  margin: 0 .5rem;
  position: relative;
  z-index: ${props => props.active ? '1' : '0'};;
  bottom: -1px;
  color: ${props => props.active ? 'inherit' : theme.primary};
  cursor: ${props => props.active ? 'default' : 'pointer'};

  &:focus {
    outline: none;
  }
`

export const TabPanel = styled.div`
  position: relative;
  padding: 2rem;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid rgba(0,0,0,.05);
  box-shadow: 0 20px 20px rgba(0,0,0,.05);
`

export const Logos = styled.div`
  padding-top: 1.5rem;
  margin-bottom: 2rem;
  
  a {
    margin-right: 1rem;
    font-size: 0;
    display: inline-block;
    vertical-align: middle;
    
    &:hover {
      filter: unset;
    }
  }
  
  img {
    vertical-align: middle;
  }
`
