import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import DocumentTitle from "react-document-title";
import {GlobalStyles} from "./styles/global";

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <DocumentTitle title="Олимпиада Даркейну"/>
        <ScrollToTop/>
        <GlobalStyles/>
        <App/>
      </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);
