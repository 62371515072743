const firebaseConfig = {
	apiKey: "AIzaSyCiJ1g-vzG2ykt4pUYFuT_YFTt9YBTJ1Gw",
	authDomain: "darkeinu-dfc32.firebaseapp.com",
	databaseURL: "https://darkeinu-dfc32.firebaseio.com",
	projectId: "darkeinu-dfc32",
	storageBucket: "darkeinu-dfc32.appspot.com",
	messagingSenderId: "162985042940",
	appId: "1:162985042940:web:2ca1462a3a5480a5e54879"
};
export default firebaseConfig;

