// set media query from breakpoint utility
import theme from "./theme";
import {css} from 'styled-components';

const {breakpoints} = theme;

const bp = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
		@media (min-width: ${breakpoints[label]}) {
			${css(...args)};
		}
	`;
  return accumulator;
}, {});

export default bp;