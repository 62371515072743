import {olimpsActionTypes} from './olimps.types'
import {firestore} from "../../firebase";

export const getOlimpsSuccess = olimps => ({
  type: olimpsActionTypes.GET_OLIMPS_SUCCESS,
  payload: olimps,
});

export const getOlimps = () => dispatch => {
  try {
    return firestore.collection('olimps')
      .onSnapshot((snapshot => {
        const olimps = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        dispatch(getOlimpsSuccess(olimps));
      }));
  } catch (err) {
    console.log('Fetching olimps error: ', err.message)
  }
};





