import React, { memo } from 'react';
import { Button, Input, Small } from '../../styles/shared';
import theme from '../../styles/theme';
import PasswordInput from '../../components/PasswordInput';

export const LoginKidsForm = memo(({ values, onChange, onSubmit, loading }) => {
  return (
    <form onSubmit={onSubmit}>
      <Input
        autoComplete="off"
        type="text"
        name="login"
        placeholder="Логин участника"
        onChange={e => onChange(e.target.value, e.target.name)}
      />

      <PasswordInput
        autoComplete="new-password"
        name="code"
        placeholder="Код"
        onChange={onChange}
        value={values.code}
      />

      <Button type="submit" block disabled={loading}>{loading ? `Проверяем...` : `Войти`}</Button>

      <Small color={theme.mute}>
        Забыл имя или код? Спроси у учителя или родителя, который тебя регистрировал
      </Small>
    </form>
  );
});
