import React, {useState, Fragment} from 'react';
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import theme from "../../styles/theme";
import bp from "../../styles/bp";
import Profile from "./Profile";
import burger from "../../assets/images/burger.svg";
import close from "../../assets/images/close.svg";
import linkIcon from "../../assets/images/link.svg";


const NavMob = ({user, links}) => {
  const [drawer, setDrawer] = useState(false);

  return (
    <Wrap>

      <MenuLink onClick={() => setDrawer(true)}>
        {user ?
          <><img src={burger} alt=""/>&nbsp;&nbsp;{user.name}</> :
          <img height={16} width={24} src={burger} alt="Меню"/>
        }
      </MenuLink>

      {drawer &&
      <Drawer>

        <Close onClick={() => setDrawer(false)}>
          <img height={18} width={18} src={close} alt="Закрыть"/>
        </Close>

        {user && <Profile user={user}/>}

        <Links>
          {links.slice(0, (user ? -1 : links.length)).map(link => (
            <Fragment key={link.path}>
              {link.href ?
                <ALink href={link.path} target="_blank" rel="noopener noreferrer">{link.label}</ALink> :
                <NavItem to={link.path} onClick={() => setDrawer(false)}>
                  {link.label}
                </NavItem>
              }
            </Fragment>
          ))}
        </Links>
      </Drawer>
      }

    </Wrap>
  );
};

export default NavMob;


const Wrap = styled.div`
  ${bp.sm`display: none;`}
`

const MenuLink = styled.button`
  font-size: 110%;
  white-space: nowrap;
  border: none;
  background: none;
  color: ${theme.primary};
  padding: .5rem 1rem;
  outline: none;
  
  ${bp.sm`display: none;`}
`

const Close = styled(MenuLink)`
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  padding-left: 4rem;
`

const Drawer = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10vh 10vw;
  box-shadow: ${theme.shadow};
  background-color: ${theme.light};
`

const Links = styled.div`
  padding: 1rem 0;
`

const NavItem = styled(NavLink)`
  font-family: ${theme.fontTitle};
  font-size: 150%;
  ${bp.sm`font-size: 110%`};
  display: block;
  line-height: 2;
  
  &.active {
    color: black;
    pointer-events: none;
  }
`

const ALink = styled.a`
  font-family: ${theme.fontTitle};
  font-size: 150%;
  ${bp.sm`font-size: 110%`};
  display: flex;
  align-items: center;
  line-height: 2;
  
  &:after {
    content: "";
    background: url(${linkIcon}) no-repeat center / contain;
    width: 10px;
    height: 10px;
    margin-left: 3px;
  }
`