import React from 'react';
import {Container} from "../../styles/shared";
import {HomeWrap} from "./Home.styled";
import {Hero} from "./Hero";
import {Login} from "./Login";

const Home = () => (
  <Container>
    <HomeWrap>
      <Hero/>
      <Login/>
    </HomeWrap>
  </Container>
);

export default Home;