import React, {useEffect, lazy, Suspense} from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {Layout, Main, Spinner} from './styles/shared';
import {connect} from 'react-redux'
import {createStructuredSelector} from "reselect";
import {selectUser, selectUserLoading} from "./store/auth/auth.selectors";
import {selectOlimpsLoading} from "./store/olimps/olimps.selectors";
import {checkUser} from "./store/auth/auth.actions";
import {getOlimps} from "./store/olimps/olimps.actions";
import {getStudents} from "./store/students/students.actions";
import Header from "./components/header/Header";
import Home from './pages/home/Home';
import Help from './pages/static/Help'
import About from './pages/static/About'
import NotFound from './pages/NotFound'

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const Signup = lazy(() => import('./pages/signup/Signup'));
const Restore = lazy(() => import('./pages/restore/Restore'));
const OlimpContainer = lazy(() => import('./pages/olimp/OlimpContainer'));
const Recap = lazy(() => import('./pages/recap/Recap'));

const App = ({user, loadingUser, loadingOlimps, dispatch}) => {
  const match = useRouteMatch("/olimp/:id");

  useEffect(() => {
    dispatch(checkUser());
    dispatch(getOlimps())
  }, [dispatch]);

  useEffect(() => {
    if (user && user.role !== 'student') {
      dispatch(getStudents(user))
    }
  }, [dispatch, user])

  return (
    <Layout>
      {!match && <Header/>}
      <Main noBg={match}>
        <Suspense fallback={<Spinner/>}>
          <Switch>
            <Route exact path="/">{loadingUser ? <Spinner/> : !user ? <Home/> : <Dashboard/>}</Route>
            <Route path="/signup">{loadingUser ? <Spinner/> : !user ? <Signup/> : <Dashboard/>}</Route>
            <Route path="/restore" component={Restore}/>
            <Route path="/help" component={Help}/>
            <Route path="/about" component={About}/>
            <Route path="/olimp/:id">{loadingOlimps ? <Spinner/> : <OlimpContainer/>}</Route>
            <Route path="/recap/:id" component={Recap}/>
            <Route component={NotFound}/>
          </Switch>
        </Suspense>
      </Main>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  loadingUser: selectUserLoading,
  loadingOlimps: selectOlimpsLoading,
})

export default connect(mapStateToProps, null)(App)