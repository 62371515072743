const validateLogin = (values, tab) => {
  let errors = '';

  // kids errors
  if (tab === 0) {
    if (values.login.length === 0 || values.code.length === 0) {
      errors = 'Заполните все поля для входа, пожалуйста';
      return errors;
    }
  }

  // adult errors
  if (tab === 1) {
    if (values.email.length === 0 || values.password.length === 0) {
      errors = 'Заполните все поля для входа, пожалуйста';
      return errors;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors = 'Укажите правильный адрес почты. Например: name@domain.com';
    } else if (values.email === 'name@domain.com') {
      errors = 'Укажите свою почту';
    }
  }

  return errors;
}

export default validateLogin;
