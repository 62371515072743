import {combineReducers} from 'redux';
import authReducer from "./auth/auth.reducer";
import olimpsReducer from "./olimps/olimps.reducer";
import studentsReducer from "./students/students.reducer";

const rootReducer = combineReducers({
	auth: authReducer,
	olimps: olimpsReducer,
	students: studentsReducer,
})

export default rootReducer