import {createSelector} from 'reselect'

const olimpsSelector = state => state.olimps

export const selectOlimps = createSelector(
  [olimpsSelector],
  olimps => olimps.olimps
);

export const selectOlimpsLoading = createSelector(
  [olimpsSelector],
  olimps => olimps.loading
);