import React from 'react';
import {Image} from "../../styles/shared";
import logo from "../../assets/images/logo.svg";
import styled from "styled-components";
import {NavLink} from "react-router-dom";

const Logo = () => {
  return (
    <Wrap exact to="/">
      <Image width={170} height={54} src={logo} alt="Олимпиада Даркейну"/>
    </Wrap>
  );
};

export default Logo;

const Wrap = styled(NavLink)`
  margin-right: auto;
  line-height: 1;
  
  &.active {
    pointer-events: none;
  }
`