import {createGlobalStyle} from 'styled-components';
import bp from "./bp";
import theme from "./theme";

import DINRegWoff from '../assets/fonts/DINNextCYR-Regular.woff';
import DINRegWoff2 from '../assets/fonts/DINNextCYR-Regular.woff2';
import DINBoldWoff from '../assets/fonts/DINNextCYR-Bold.woff';
import DINBoldWoff2 from '../assets/fonts/DINNextCYR-Bold.woff2';

export const GlobalStyles = createGlobalStyle`

    html, body {
      margin: 0; 
      padding: 0;
      box-sizing: border-box;
    }

    *, *:before, *:after {
      box-sizing: inherit;
    }
    
    html {
      font-size: 95%;
      
      ${bp.sm`
		font-size: 100%;
	  `}
      
      ${bp.md`
        font-size: 125%;
      `}
    }
    
    @font-face {
      font-family: 'DIN Next CYR';
      src: url(${DINBoldWoff2}) format('woff2'), url(${DINBoldWoff}) format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }
    
    @font-face {
      font-family: 'DIN Next CYR';
      src: url(${DINRegWoff2}) format('woff2'), url(${DINRegWoff}) format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    
    body {
      font-family: ${theme.fontText};
      line-height: 1.5;
    }

	h1, h2, h3, h4, h5, h6 {
	  font-family: ${theme.fontTitle};
      line-height: 1.2;
      letter-spacing: .5px;
      margin-top: 0;
	}
	
    a {
      color: ${theme.primary};
      text-decoration: none;
      transition: .2s all ease-in-out;
      
      &:hover {
        filter: brightness(90%);
      }
    }
    
    p {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
    
    small {
      font-size: 70%;
    }
	
`;
