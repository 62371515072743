import React, {useState} from 'react';
import {Input} from "../styles/shared";
import eye from "../assets/images/eye.svg";
import styled from "styled-components";


const PasswordInput = ({name, placeholder, onChange, value, autoComplete}) => {
  const [passShown, setPassShown] = useState(false);
  return (
    <Wrap>
      <Input
        autoComplete={autoComplete}
        type={passShown ? "text" : "password"}
        name={name}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value, e.target.name)}
        maxLength={100}
        value={value}
      />
      <i onClick={() => setPassShown(!passShown)}>
        <img width={36} src={eye} alt="👁"/>
      </i>
    </Wrap>
  );
};

export default PasswordInput;

const Wrap = styled.div`
  position: relative;
  
  input {
    padding-right: 50px;
  }
  
  i {
    position: absolute;
    display: inline-block;
    top: 3px;
    right: 3px;
    line-height: 0;
    cursor: pointer;
  }
`
