import {userActionTypes} from './auth.types'
import {auth, firestore} from "../../firebase";

export const getUserStart = () => ({
  type: userActionTypes.GET_USER_START,
});

export const getUserSuccess = user => ({
  type: userActionTypes.GET_USER_SUCCESS,
  payload: user,
});

export const checkUser = () => dispatch => {
  dispatch(getUserStart());

  try {
    return auth.onAuthStateChanged(async user => {
      if (user) {
        const userRef = await firestore.doc(`users/${user.uid}`);
        await userRef.onSnapshot(snap => {
          if (snap.data()) {
            dispatch(getUserSuccess({
              id: snap.id,
              isVerified: user.emailVerified,
              ...snap.data(),
            }));
          }
        });
      } else {
        dispatch(getUserSuccess(null));
      }
    })
  } catch (err) {
    console.log('Checking user error: ', err.message)
  }
};





