import React from "react";
import toaster from "toasted-notes";
import styled from "styled-components";
import theme from "../styles/theme";
import close from "../assets/images/close.svg";

export const showSuccess = (data) => (
  toaster.notify(() => <Wrap success dangerouslySetInnerHTML={{__html: data}}/>)
);

export const showError = (data) => {
  return (
    toaster.notify(({onClose}) => (
      <Wrap error>
        <div dangerouslySetInnerHTML={{__html: data}}/>
        <button onClick={onClose}><img src={close} alt="x"/></button>
      </Wrap>
    ), {position: 'top-right', duration: 5000})
  )
};


export const showInfo = (data) => {
  return (
    toaster.notify(({onClose}) => (
      <Wrap info>
        <div dangerouslySetInnerHTML={{__html: data}}/>
        <button onClick={onClose}><img src={close} alt="x"/></button>
      </Wrap>
    ), {duration: null})
  )
};

const Wrap = styled.div`
  background-color: ${props => props.error ? '#fffbe6' : '#fff'};
  border: 2px solid ${props => props.error ? '#f6bc07' : theme.primary};
  border-radius: 6px;
  box-shadow: ${theme.shadow};
  margin: .5rem;
  padding: .5rem 1rem;
  max-width: ${props => props.info ? '600px' : '400px'};
  display: flex;
  align-items: flex-start;
  font-size: 85%;
  position: relative;
  text-align: left;
  
  > div {
    padding-right: 1.5rem;
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  
  button {
    -webkit-appearance: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    border: none;
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    top: .7rem;
    right: .3rem;
    display: flex;
  }
`