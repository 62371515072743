import React, {memo} from "react";
import close from "../assets/images/close.svg";
import {ButtonLink} from "../styles/shared";
import styled from "styled-components";

const Modal = memo(({children, modal, setModal, bg, width, height, p, backDisabled, closeDisabled,}) => {
  return (
    <>
      {modal ?
        <Wrap>
          <Back onClick={() => backDisabled ? null : setModal()}/>
          <Body width={width} bg={bg} p={p} height={height}>
            {!closeDisabled &&
            <Close>
              <ButtonLink onClick={() => setModal()}>
                <img src={close} alt="Close"/>
              </ButtonLink>
            </Close>
            }
            {children}
          </Body>
        </Wrap> : null}
    </>
  );
});

export default Modal;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: .5rem;
`;

export const Back = styled.div`
  position: fixed;
  z-index: 210;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.9);
`;

const Body = styled.div` 
  background-color: ${props => props.bg ? props.bg : "white"};
  max-width: ${props => props.width ? `${props.width}px` : "600px"};
  width: 100%;
  height:auto;
  min-height: ${props => props.height ? `${props.height}px` : "unset"};
  position: relative;
  z-index: 220;
  border-radius: 8px;
  overflow: auto;
  padding: ${props => props.p ? props.p : "1rem"};
`;

const Close = styled.div` 
  position: absolute;
  top: .5rem;
  right: .75rem;
`;