import React, {useEffect, useState} from 'react';
import {Container, Spinner} from "../../styles/shared";
import DocumentTitle from "react-document-title";
import {firestore} from "../../firebase";
import {showError} from "../../components/Toasts";
import styled from "styled-components";
import bp from "../../styles/bp";

const About = () => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const {title, body} = values;

  useEffect(() => {
    setLoading(true);
    firestore
      .collection('content').doc('about').get()
      .then(doc => {
        setLoading(false);
        setValues(doc.data());
      })
      .catch(e => {
        setLoading(false);
        showError('Что-то пошло не так. Попробуйте позже');
        console.error('Error loading document', e);
      })
  }, [])


  return (
    !loading ?
      <Container width={960}>
        <DocumentTitle title={title ? title : ''}/>
        <Body dangerouslySetInnerHTML={{__html: body}}/>
      </Container> :
      <Spinner/>
  );
};

export default About;

const Body = styled.div`
  margin-bottom: 15vw;
  
  .__se__float-right {
    display: none;
    ${bp.sm`float: right; display: block`};
    margin-left: 1rem;  
  }
  
  .__se__float-left {
    display: none;
    ${bp.sm`float: left; display: block`};
    margin-right: 2rem;
  }
  
  .se-video-container {
  
    figure {
      position: relative;
      
      iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border: none;
        border-radius: 5px;
      }
    }
  }
`