import {userActionTypes} from './auth.types'

const INITIAL_STATE = {
  user: null,
  loading: true,
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userActionTypes.GET_USER_START:
      return {
        ...state,
        loading: true,
      };
    case userActionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    default:
      return state
  }
};

export default authReducer;
