import {studentsActionTypes} from './students.types'

const INITIAL_STATE = {
  students: null,
  loading: true,
}

const studentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case studentsActionTypes.GET_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload,
        loading: false,
      };
    default:
      return state
  }
};

export default studentsReducer;
