import React, {memo} from 'react';
import {Tab, Tabs} from "./Home.styled";

const data = ['Для детей', 'Для взрослых'];

export const TabsSwitch = memo(({tab, setValues}) => {

  const onSwitch = tab => setValues(prev => ({...prev, tab,}));

  return (
    <Tabs>
      {
        data.map((name, i) => (
          <Tab key={i} active={tab === i} onClick={() => onSwitch(i)}>{name}</Tab>
        ))
      }
    </Tabs>
  );
});
