import React from 'react';
import DocumentTitle from "react-document-title";
import {Container, H1} from "../styles/shared";

const NotFound = () => {
  return (
    <Container>
      <DocumentTitle title="Страница не найдена. 404"/>
      <H1>Страница не найдена</H1>
    </Container>
  );
};

export default NotFound;