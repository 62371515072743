import {studentsActionTypes} from './students.types'
import {firestore} from "../../firebase";

export const getStudentsSuccess = students => ({
  type: studentsActionTypes.GET_STUDENTS_SUCCESS,
  payload: students,
});

export const getStudents = user => async dispatch => {
  try {
    let snap;
    if (user.role === 'parent') {
      snap = await firestore
        .collection("users")
        .where("parent.id", "==", user.id)
    }
    if (user.role === 'teacher') {
      snap = await firestore
        .collection("users")
        .where('location', 'array-contains', user.location[2])
        .where('role', '==', 'student');
    }
    snap.onSnapshot(querySnapshot => {
      const students = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      dispatch(getStudentsSuccess(students));
    });
  } catch (err) {
    console.log('Fetching students error: ', err.message)
  }
};








